define("cc-frontend/components/planner/menus/parts/share-link", ["exports", "cc-frontend/lib/actions/app-state/SHARE_PLANBOOK", "lodash-es", "moment", "cc-frontend/app"], function (_exports, _SHARE_PLANBOOK, _lodashEs, _moment, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "FJJXd/wv",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cc-frontend/components/planner/menus/parts/share-link.hbs",
    "isStrictMode": false
  });

  let ShareLinkComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class ShareLinkComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "closeMenu", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "perspective", void 0);

      _initializerDefineProperty(this, "dialog", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "rpc", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _defineProperty(this, "onClick", () => {});
    }

    trackAnalytics() {
      let currentRoute = this.router.currentRoute.name.split(".").pop();
      this.store.dispatchPreparedAction((0, _SHARE_PLANBOOK.default)({
        planbookId: this.planbook.id,
        planbookTitle: this.planbook.attributes.title,
        perspective: currentRoute
      }));
    }

    click() {
      this.closeMenu();
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.append("hideNav", "true");
      let path = window.location.pathname; // if we're in a planner route, add the date as the default date is today and won't be in the URL

      if ((0, _lodashEs.includes)(this.router.currentRoute.name, "planner") && urlParams.get("date") === null) {
        urlParams.append("date", (0, _moment.default)().format("YYYY-MM-DD"));
      }

      let url = `${window.location.protocol}//${window.location.host}${path}?${urlParams.toString()}`;

      if (this.planbook.attributes.publicAccess.roles.length === 0) {
        this.dialog.blank("dialogs/ask-to-share-planbook-publicly", {
          className: "dialog--create-annotation"
        }).then(() => {
          this.rpc.planbook_collaboration_change_public_permission({
            planbookId: this.planbook.id,
            role: "VIEWER"
          });
          this.trackAnalytics();
          this.dialog.blank("dialogs/public-link", {
            url: url,
            className: "dialog--create-annotation"
          }).then(() => {
            this.onClick();
          }).catch(error => {
            if (error instanceof Error) (0, _app.handleError)(error);
          });
        }).catch(error => {
          if (error instanceof Error) (0, _app.handleError)(error);
        });
      } else {
        this.trackAnalytics();
        this.dialog.blank("dialogs/public-link", {
          url: url,
          className: "dialog--create-annotation"
        }).then(() => {
          this.onClick();
        }).catch(error => {
          if (error instanceof Error) (0, _app.handleError)(error);
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ShareLinkComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ShareLinkComponent);
});