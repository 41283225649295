define("cc-frontend/templates/new-group/show/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZsUEaVtS",
    "block": "[[[1,[28,[35,0],[\"Admin Dashboard\"],null]],[1,\"\\n\"],[8,[39,1],null,[[\"@title\"],[\"group.admin-dashboard\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"session\",\"user\",\"isFulfilled\"]],[[[41,[28,[37,3],[[30,0,[\"session\",\"user\",\"content\"]],\"ADMIN_DASHBOARD\"],null],[[[41,[28,[37,4],[[30,1,[\"meta\",\"permissions\"]],\"can-manage-group\"],null],[[[1,\"      \"],[8,[39,5],null,[[\"@group\",\"@tabName\"],[[30,1],[30,0,[\"tabName\"]]]],null],[1,\"\\n      \"],[10,0],[14,0,\"school-backdrop\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"school-backdrop__fade\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"school-backdrop__pattern\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"school-backdrop__gradient\"],[15,5,[28,[37,6],[[28,[37,7],[\"background-image: linear-gradient(90deg, \",[30,1,[\"attributes\",\"branding\",\"primaryColor\"]],\", \",[30,1,[\"attributes\",\"branding\",\"secondaryColor\"]]],null]],null]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n\"]],[]],[[[1,\"      Not authorized\\n\"]],[]]]],[]],[[[1,\"    Feature not enabled\\n\"]],[]]]],[]],null]],[\"@model\"],false,[\"page-title\",\"record-page-visit\",\"if\",\"has-feature\",\"includes\",\"group/dashboard/dashboard-nav\",\"html-safe\",\"concat\",\"component\",\"-outlet\"]]",
    "moduleName": "cc-frontend/templates/new-group/show/dashboard.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});