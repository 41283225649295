define("cc-frontend/lib/actions/lesson/LESSON_COPY", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _lodashEs, _defaultLessonContext, _defaultLessonModels, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = copyLessonAction;

  function copyLessonAction(payload) {
    return {
      name: "LESSON_COPY",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    // See PLANBOOK_COPY_ALL_LESSONS_FOR_DAY
    // IF use this again, abstract!
    let lessonAttributes = (0, _lodashEs.cloneDeep)(payload.lessonToCopy.attributes);
    lessonAttributes.parentCardStack = {
      id: payload.lessonToCopy.id,
      version: payload.lessonToCopy.attributes.version,
      ownerIds: payload.ownerIds,
      courseId: payload.courseId,
      planbookId: payload.planbookId
    };

    let patch = _patchUpdater.default.create("user", payload.currentUserId).set("attributes.clipboard.cardStackAttributes", lessonAttributes).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let cardStackTitle = models.cardStack ? models.cardStack.attributes.title : null;
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbookId,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: [payload.planbookId, payload.courseId, payload.cardStackId],
      primary_verb: "copy",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
      primary_object_type: "lesson",
      primary_object_specifier: null,
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_specifier: null,
      adverbial_object_id: null,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: [payload.planbookId, payload.courseId, payload.cardStackId]
    };
    return {
      title: "Lesson Copied",
      titleForAnalytics: "Lesson - Copy",
      description: `Copied "${cardStackTitle}"`,
      context: (0, _defaultLessonContext.default)(payload, models),
      activity: null,
      newAnalyticsEvents: [newEvent]
    };
  }
});