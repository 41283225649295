define("cc-frontend/lib/actions/course/calendar/MOVE_LESSON_TO_DATE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "lodash-es", "moment", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _unitUtils, _lodashEs, _moment, _defaultLessonModels, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareMoveLessonToDate = prepareMoveLessonToDate;
  _exports.default = _exports.MOVE_LESSON_TO_DATE = void 0;

  function prepareMoveLessonToDate(payload) {
    return {
      name: MOVE_LESSON_TO_DATE.name,
      payload: payload,
      patches: MOVE_LESSON_TO_DATE.patches(payload),
      undoPatches: MOVE_LESSON_TO_DATE.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(MOVE_LESSON_TO_DATE.narrative, payload)
    };
  }

  const MOVE_LESSON_TO_DATE = {
    name: "MOVE_LESSON_TO_DATE",
    params: {
      cardStackId: "string",
      fromDate: "string",
      toDate: "number",
      course: "object",
      planbook: "object",
      rotationCalendar: "object"
    },

    patches(payload) {
      let schoolDays = payload.rotationCalendar.attributes.schoolDays;
      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let {
        transferrableArray,
        courseDatesWithoutLesson
      } = (0, _unitUtils.extractLesson)(payload.cardStackId, payload.course.attributes.calendar.dates);
      let newCourseDates = (0, _unitUtils.insertRange)(transferrableArray, payload.toDate, 0, null, courseDatesWithoutLesson, consolidatedCourseDatesOff, schoolDays, true);
      let {
        courseDates,
        units
      } = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, schoolDays);

      let patch = _patchUpdater.default.create("course", payload.course.id).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).inc(`attributes.calendar.version`, 1).patch;

      let updateLessonPatch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("relationships.course.data.id", payload.course.id).set("relationships.planbook.data.id", payload.planbook.id).set("attributes.usedAs", "lesson").set("meta.permissions", payload.course.meta.permissions).patch; // to make sure we have the right permissions if this is new
      // patch.unitIdsOnTheMove   = unitIdsOnTheMove


      patch.cardStackIdsOnTheMove = [payload.cardStackId];
      return [patch, updateLessonPatch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).inc("attributes.calendar.version", 1).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      var _models$course, _models$planbook, _models$cardStack, _models$course2;

      let course = findFn("course", payload.course.id);
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let courseId = (_models$course = models.course) === null || _models$course === void 0 ? void 0 : _models$course.id;
      let planbookId = (_models$planbook = models.planbook) === null || _models$planbook === void 0 ? void 0 : _models$planbook.id;
      let lessonTitle = ((_models$cardStack = models.cardStack) === null || _models$cardStack === void 0 ? void 0 : _models$cardStack.attributes.title) || "Blank Lesson";
      let formattedToDate = (0, _moment.default)(payload.toDate).format("ddd, MMM. D, YYYY");
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "move",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: null,
        adverbial_verb: "dragging_dropping",
        adverbial_object_type: "lesson",
        adverbial_object_specifier: null,
        adverbial_object_id: payload.cardStackId,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      return {
        title: "Moved Lesson",
        titleForAnalytics: "Lesson - Move",
        description: `Moved "${lessonTitle}" to ${formattedToDate}`,
        context: {
          lessonTitle: lessonTitle,
          triggeredBy: "dragging",
          courseTitle: models === null || models === void 0 ? void 0 : (_models$course2 = models.course) === null || _models$course2 === void 0 ? void 0 : _models$course2.attributes.title,
          planbookTitle: models.planbook.attributes.title,
          cardStackId: payload.cardStackId,
          courseId: payload.course.id,
          planbookId: models.planbook.id
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `lesson-${payload.cardStackId}`,
          to: [`course-edits:course-${courseId}`, `planbook-edits:planbook-${planbookId}`]
        },
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.MOVE_LESSON_TO_DATE = MOVE_LESSON_TO_DATE;
  var _default = MOVE_LESSON_TO_DATE;
  _exports.default = _default;
});