define("cc-frontend/components/planner/planbook/create-edit/courses-editor/add-menu.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "options-wrapper": "_options-wrapper_wklxvk",
    "option-button": "_option-button_wklxvk",
    "option-button-header": "_option-button-header_wklxvk",
    "option-button-subheader": "_option-button-subheader_wklxvk",
    "add-button": "_add-button_wklxvk d-s-shadowed-button",
    "example-list": "_example-list_wklxvk",
    "item": "_item_wklxvk"
  };
  _exports.default = _default;
});