define("cc-frontend/lib/actions/lesson/LESSON_CARD_COPY", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "lodash-es", "cc-frontend/models/analytics/planbook", "cc-frontend/models/analytics/base", "cc-frontend/lib/actions/lesson/default-lesson-activity"], function (_exports, _patchUpdater, _defaultLessonContext, _defaultLessonModels, _lodashEs, _planbook, _base, _defaultLessonActivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lessonCardCopyAction;

  function lessonCardCopyAction(payload) {
    return {
      name: "LESSON_CARD_COPY",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let card = (0, _lodashEs.omit)(payload.section, ["id"]);

    let patch = _patchUpdater.default.create("user", payload.currentUserId).set("attributes.clipboard.card", (0, _lodashEs.cloneDeep)(card)).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let cardTitle = (0, _lodashEs.get)(payload.section, "attributes.title");
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "copy",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "cards",
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_specifier: null,
      adverbial_object_id: null,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
    };
    return {
      title: "Lesson Card Copied",
      titleForAnalytics: "Lesson Card - Copy",
      description: `Copied the "${cardTitle}" card`,
      context: (0, _defaultLessonContext.default)(payload, models),
      activity: (0, _defaultLessonActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }
});