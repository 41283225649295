define("cc-frontend/services/modal-manager", ["exports", "@marcj/marshal", "lodash-es", "tracked-built-ins"], function (_exports, _marshal, _lodashEs, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _class3, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Scott got this from Stackoverflow but forgot where.  Any bugs are the original posters
  // fault. All the success is from Scott.
  const tuple = (...args) => args;

  const slideoutModalNames = tuple("group/create-team", "group/add-members", "group/select-teams", "group/share-unshare-planbooks", "school/edit/name", "school/edit/payment/change-purchased-seats", "user/account", "user/create-planbook", "admin/fiscal-group/change-state", "planner/course/timeline/sidebar", "planner/modals/google-classroom", "planner/modals/google-sign-in", "planner/modals/print-paywall", "planner/modals/print-download", "planner/modals/edit-annotation", "planner/planbook/copy-planbook/modal", "planner/planbook/special-events/list-modal");
  const fullScreenModalNames = tuple("planner/modals/edit-course", "planner/modals/full-screen", "planner/modals/lesson-history", "planner/modals/template-library", "planner/planbook/create-edit/edit-modal", "planner/modals/import-lessons", "planner/modals/unit-comparison-modal", "planner/modals/weekly-order", "school/setup", "user/create-school-form");
  const scrollableModals = tuple("planner/course/timeline/sidebar");
  /**
   * This is what you pass into the modal.
   */

  const modalNames = (0, _lodashEs.concat)(slideoutModalNames, fullScreenModalNames);
  let ModalProps = (_class = class ModalProps {
    constructor() {
      _initializerDefineProperty(this, "isVisible", _descriptor, this);

      _initializerDefineProperty(this, "model", _descriptor2, this);

      _initializerDefineProperty(this, "order", _descriptor3, this);

      _initializerDefineProperty(this, "modalName", _descriptor4, this);

      _initializerDefineProperty(this, "modalType", _descriptor5, this);

      _initializerDefineProperty(this, "onClose", _descriptor6, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isVisible", [_trackedBuiltIns.tracked, _marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "model", [_trackedBuiltIns.tracked, _marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "order", [_trackedBuiltIns.tracked, _marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modalName", [_trackedBuiltIns.tracked, _marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalType", [_trackedBuiltIns.tracked, _marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_trackedBuiltIns.tracked, _marshal.field], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  })), _class);
  let ModalManager = (_class3 = class ModalManager extends Ember.Service {
    // TODO this should be converted into a getter which just counts which modals have `isVisible`
    // set to true and then adds in the modalsOPenedOutsisdeManager to it.
    // @ts-ignore This should really be fine.
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "openedModalsCount", _descriptor7, this);

      _defineProperty(this, "openedModals", (0, _trackedBuiltIns.tracked)([]));

      _defineProperty(this, "modals", void 0);

      this.modals = modalNames.map(name => {
        let modalType = (0, _lodashEs.includes)(fullScreenModalNames, name) ? "FULL_SCREEN" : "SLIDEOUT";
        return (0, _marshal.plainToClass)(ModalProps, {
          isVisible: false,
          model: null,
          order: 0,
          modalName: name,
          modalType: modalType,
          onClose: undefined
        });
      });
    }

    openModal(componentName, model, opts) {
      let modal = (0, _lodashEs.find)(this.modals, modalProp => modalProp.modalName === componentName);
      if ((0, _lodashEs.isNil)(modal)) throw Error(`${componentName} has not been added to the modal manager. Go to services/modal-manager.ts to add it.`);

      if (modal.isVisible === false) {
        this.openedModalsCount++;
        modal.isVisible = true;
      }

      this.openedModals.push(componentName);
      modal.model = model;
      modal.order = this.openedModalsCount;
      modal.onClose = opts === null || opts === void 0 ? void 0 : opts.onClose;
      let appBody = document.querySelector("body");
      appBody === null || appBody === void 0 ? void 0 : appBody.classList.add("modal-manager-open");

      if ((0, _lodashEs.includes)(scrollableModals, modal.modalName)) {
        appBody === null || appBody === void 0 ? void 0 : appBody.classList.add("--is-scrollable");
      } else {
        appBody === null || appBody === void 0 ? void 0 : appBody.classList.remove("--is-scrollable");
      }
    }

    closeModal(componentName) {
      let modal = (0, _lodashEs.find)(this.modals, modalProp => modalProp.modalName === componentName);
      if ((0, _lodashEs.isNil)(modal)) throw Error(`${componentName} has not been added to the modal manager. Go to services/modal-manager.ts to add it.`);

      if (modal.isVisible === true) {
        modal.isVisible = false;
        this.openedModalsCount = this.openedModalsCount - 1;
        let closePromise = new Promise(resolve => {
          // Scott's pretty sure this is delayed to let the animation complete
          Ember.run.later(() => {
            if (modal) {
              modal.model = null;
              modal.order = 0;
              resolve();
            }
          }, 500);
        });
        if (modal.onClose) modal.onClose(closePromise);
        let appBody = document.querySelector("body");

        if (this.openedModalsCount === 0) {
          appBody === null || appBody === void 0 ? void 0 : appBody.classList.remove("modal-manager-open");
        }

        (0, _lodashEs.pull)(this.openedModals, componentName);
        let lastModalOpened = (0, _lodashEs.last)((0, _lodashEs.sortBy)((0, _lodashEs.filter)(this.modals, "isVisible"), "order"));

        if (!(0, _lodashEs.isNil)(lastModalOpened) && (0, _lodashEs.includes)(scrollableModals, lastModalOpened.modalName)) {
          appBody === null || appBody === void 0 ? void 0 : appBody.classList.add("--is-scrollable");
        } else {
          appBody === null || appBody === void 0 ? void 0 : appBody.classList.remove("--is-scrollable");
        }
      }
    }

    closeAllModals() {
      var _document$querySelect;

      (0, _lodashEs.forEach)(this.modals, modalProp => {
        if (modalProp.isVisible === true) {
          modalProp.isVisible = false;
          --this.openedModalsCount;
        }
      }); // @ts-ignore

      this.openedModals = (0, _trackedBuiltIns.tracked)([]);
      (_document$querySelect = document.querySelector("body")) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.classList.remove("modal-manager-open");
    }

    openModalOutsideManager() {
      var _document$querySelect2;

      this.openedModalsCount = this.openedModalsCount + 1;
      (_document$querySelect2 = document.querySelector("body")) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.classList.add("modal-manager-open");
    }

    closeModalOutsideManager() {
      this.openedModalsCount = this.openedModalsCount - 1;

      if (this.openedModalsCount === 0) {
        var _document$querySelect3;

        (_document$querySelect3 = document.querySelector("body")) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.classList.remove("modal-manager-open");
      }
    }

  }, (_descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "openedModalsCount", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  })), _class3);
  _exports.default = ModalManager;
});