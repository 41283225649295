define("cc-frontend/lib/actions/course/COURSE_UPDATE_STANDARD_SET_IDS", ["exports", "lodash", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/create-narrative"], function (_exports, _lodash, _patchUpdater, _createNarrative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COURSE_UPDATE_STANDARD_SET_IDS = void 0;
  const COURSE_UPDATE_STANDARD_SET_IDS = {
    name: "COURSE_UPDATE_STANDARD_SET_IDS",
    params: {
      courseId: "string",
      planbookId: {
        type: "string",
        optional: true
      },
      standardSetIds: {
        type: "array",
        items: {
          type: "string"
        }
      }
    },

    patches(payload) {
      let relationships = _lodash.default.map(payload.standardSetIds, id => {
        return {
          id: id,
          type: "standard-set"
        };
      });

      let patch = _patchUpdater.default.create("course", payload.courseId).set("relationships.standardSets.data", relationships).set("attributes.standardSetIds", payload.standardSetIds).patch;

      return [patch];
    },

    undoPatches: null,

    narrative(payload) {
      return (0, _createNarrative.default)({
        context: {
          standardSetIds: payload.standardSetIds
        }
      });
    }

  };
  _exports.COURSE_UPDATE_STANDARD_SET_IDS = COURSE_UPDATE_STANDARD_SET_IDS;
  var _default = COURSE_UPDATE_STANDARD_SET_IDS;
  _exports.default = _default;
});