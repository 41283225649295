define("cc-frontend/routes/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PrintRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class PrintRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "finder", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "fastboot", _descriptor3, this);
    }

    // queryParams: {token: null, userId: null},
    model(params) {
      if (params.printToken) {
        Ember.set(this, "session.token", params.printToken);
        Ember.set(this, "session.userId", params.printUserId);
      }

      let promiseCheckCount = 0;

      if (Ember.get(this, "fastboot") && Ember.get(this, "fastboot.isFastBoot")) {
        let promise = new Ember.RSVP.Promise((resolve, reject) => {
          let poll = () => {
            // Ember.run.sync()
            Ember.run.later(() => {
              // Increment everytime we make the loop and we hvae no inFlightRequests
              if (Ember.get(this, "finder.inFlightRequests.length") === 0) {
                promiseCheckCount++;
              } // Reset if we get new requests


              if (Ember.get(this, "finder.inFlightRequests.length") > 0) {
                promiseCheckCount = 0;
              }

              if (Ember.get(this, "finder.inFlightRequests.length") === 0 && promiseCheckCount > 10) {
                // Ember.run.sync()
                Ember.run.scheduleOnce("afterRender", () => {
                  Ember.run.later(resolve, 250);
                });
                return;
              } else {
                poll();
              }
            }, 100);
          };

          poll();
        });
        Ember.get(this, "fastboot").deferRendering(promise);
      }

      return new Ember.RSVP.Promise((resolve, _reject) => resolve());
    }

    afterModel() {}

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "finder", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PrintRoute;
});