define("cc-frontend/lib/actions/app-state/SHARE_PLANBOOK", ["exports", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/create-narrative", "cc-frontend/lib/actions/planbook/default-planbook-context", "cc-frontend/lib/actions/planbook/default-planbook-models"], function (_exports, _base, _lodashEs, _createNarrative, _defaultPlanbookContext, _defaultPlanbookModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sharePlanbookAction;

  function sharePlanbookAction(payload) {
    return {
      name: "SHARE_PLANBOOK",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultPlanbookModels.default)(payload, findFn, userId, payload.planbookId);
    let context = (0, _defaultPlanbookContext.default)(payload, models);
    let titleForAnalytics;
    let primaryObjectSpecifier;

    if (payload.perspective === "day") {
      titleForAnalytics = "Planbook - Share Day";
      primaryObjectSpecifier = "day";
    } else if (payload.perspective === "week") {
      titleForAnalytics = "Planbook - Share Week";
      primaryObjectSpecifier = "week";
    } else if (payload.perspective === "month") {
      titleForAnalytics = "Planbook - Share Month";
      primaryObjectSpecifier = "month";
    } else if (payload.perspective === "standards-tracker") {
      titleForAnalytics = "Planbook - Share Standards Tracker";
      primaryObjectSpecifier = "standards_tracker";
    } else if (payload.perspective === "timeline") {
      titleForAnalytics = "Planbook - Share Unit Timeline";
      primaryObjectSpecifier = "timeline";
    } else if (payload.perspective === "outline") {
      titleForAnalytics = "Planbook - Share Outline";
      primaryObjectSpecifier = "outline";
    }

    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbookId,
      root_type: "planbook",
      primary_object_type: "planbook",
      primary_possessive: "owns",
      primary_object_id_and_ancestor_ids: [payload.planbookId],
      primary_verb: "share",
      primary_object_specifier: primaryObjectSpecifier,
      adverbial_verb: null,
      adverbial_object_type: "lesson",
      adverbial_object_specifier: null
    };
    return (0, _createNarrative.default)({
      title: `Shared link from ${newEvent.primary_object_specifier}`,
      titleForAnalytics: titleForAnalytics,
      description: `Copied link to planbook ${newEvent.primary_object_specifier}`,
      context: context,
      newAnalyticsEvents: [newEvent]
    });
  }
});