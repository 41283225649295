define("cc-frontend/components/planner/planbook/create-edit/courses-editor.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "wrapper": "_wrapper_1vz4vn",
    "--edit-modal": "_--edit-modal_1vz4vn",
    "add-course-button__group": "_add-course-button__group_1vz4vn",
    "add-course-button": "_add-course-button_1vz4vn d-s-shadowed-button",
    "cancel-button": "_cancel-button_1vz4vn",
    "edit-class-modal": "_edit-class-modal_1vz4vn",
    "course-list": "_course-list_1vz4vn",
    "course": "_course_1vz4vn",
    "course-header": "_course-header_1vz4vn",
    "course-title": "_course-title_1vz4vn",
    "edit-button": "_edit-button_1vz4vn",
    "course__left": "_course__left_1vz4vn",
    "course__right": "_course__right_1vz4vn"
  };
  _exports.default = _default;
});