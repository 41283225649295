define("cc-frontend/components/planner/menus/day-menu/primary/body-read-only", ["exports", "@ember-decorators/component", "cc-frontend/lib/actions/planbook/lessons/PLANBOOK_COPY_ALL_LESSONS_FOR_DAY", "cc-frontend/lib/unit-utils", "lodash", "lodash-es", "moment"], function (_exports, _component, _PLANBOOK_COPY_ALL_LESSONS_FOR_DAY, _unitUtils, _lodash, _lodashEs, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DS::DropdownMenu::SingleButtonRow
    @isAtTopOfMenu={{true}}
    @iconName="icons/copy-doc"
    @clickAction={{this.copyLessonsForDay}}
  >
    Copy Lessons
  </DS::DropdownMenu::SingleButtonRow>
  <DS::DropdownMenu::SingleButtonRow
    @iconName="icons/print-text"
    @clickAction={{this.printOrDownload}}
  >
    Print/Download Lessons
  </DS::DropdownMenu::SingleButtonRow>
  */
  {
    "id": "zPkFK95m",
    "block": "[[[8,[39,0],null,[[\"@isAtTopOfMenu\",\"@iconName\",\"@clickAction\"],[true,\"icons/copy-doc\",[30,0,[\"copyLessonsForDay\"]]]],[[\"default\"],[[[[1,\"\\n  Copy Lessons\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,0],null,[[\"@iconName\",\"@clickAction\"],[\"icons/print-text\",[30,0,[\"printOrDownload\"]]]],[[\"default\"],[[[[1,\"\\n  Print/Download Lessons\\n\"]],[]]]]]],[],false,[\"d-s/dropdown-menu/single-button-row\"]]",
    "moduleName": "cc-frontend/components/planner/menus/day-menu/primary/body-read-only.hbs",
    "isStrictMode": false
  });

  let DayMenuPrimaryMenuComponent = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = (_class2 = class DayMenuPrimaryMenuComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "courses", void 0);

      _defineProperty(this, "planbook", void 0);

      _defineProperty(this, "dateString", void 0);

      _defineProperty(this, "closeMenu", void 0);

      _defineProperty(this, "isFilteringClasses", void 0);

      _defineProperty(this, "hiddenCourseIds", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "modalManager", _descriptor3, this);
    }

    copyLessonsForDay() {
      this.closeMenu();

      if (this.session.isAuthenticated !== true || this.session.userId === null) {
        this.session.mustSignInToProceed("Copy Lessons for Day");
        return;
      }

      let lessonsForCourses = (0, _lodashEs.compact)((0, _lodashEs.map)(this.courses, course => {
        let cardStackId = (0, _unitUtils.getCardStackIdForDate)(course.attributes.calendar.dates, this.dateString);
        let cardStack = this.store.findInMemory("card-stack", cardStackId);

        if (cardStack) {
          let attrs = (0, _lodashEs.cloneDeep)(cardStack.attributes);
          attrs.parentCardStack = {
            id: cardStack.id,
            version: cardStack.attributes.version,
            planbookId: this.planbook.id,
            courseId: course.id,
            ownerIds: [this.planbook.relationships.owner.data.id]
          };
          return {
            courseId: course.id,
            cardStackAttrs: attrs
          };
        } else {
          return undefined;
        }
      }));
      this.store.dispatchPreparedAction((0, _PLANBOOK_COPY_ALL_LESSONS_FOR_DAY.default)({
        currentUserId: this.session.userId,
        lessonsForCourses: lessonsForCourses,
        dateString: this.dateString,
        ownerIds: [this.planbook.relationships.owner.data.id],
        planbookId: this.planbook.id
      }));
    }

    printOrDownload() {
      this.closeMenu();

      if (this.session.isAuthenticated !== true) {
        this.session.mustSignInToProceed("Print/Download Day");
        return;
      }

      if (_lodash.default.includes(this.session.user.content.meta.permissions, "can-print-day")) {
        this.modalManager.openModal("planner/modals/print-download", {
          courseIds: _lodash.default.chain(this.courses).map(course => course.id).reject(courseId => _lodash.default.includes(this.hiddenCourseIds, courseId)).value(),
          planbookId: this.planbook.id,
          startDate: this.dateString,
          endDate: this.dateString,
          layout: "planbook-date:single",
          currentUserId: this.session.userId,
          isFilteringClasses: this.isFilteringClasses,
          canPrint: _lodash.default.includes(this.session.user.content.meta.permissions, "can-print-day"),
          dateRangeString: (0, _moment.default)(this.dateString).format("dddd, MMMM Do YYYY")
        });
      } else {
        this.modalManager.openModal("planner/modals/print-paywall", {});
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "modalManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "copyLessonsForDay", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "copyLessonsForDay"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "printOrDownload", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "printOrDownload"), _class2.prototype)), _class2)) || _class);
  _exports.default = DayMenuPrimaryMenuComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DayMenuPrimaryMenuComponent);
});