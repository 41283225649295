define("cc-frontend/lib/actions/planbook/lessons/PLANBOOK_COPY_ALL_LESSONS_FOR_DAY", ["exports", "@mobily/ts-belt", "cc-frontend/lib/patch-updater", "cc-frontend/lib/find-course-date", "lodash-es", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-activity", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/lesson", "cc-frontend/lib/actions/lesson/default-lesson-models", "moment", "cc-frontend/models/analytics/planbook", "cc-frontend/lib/actions/planbook/lessons/default-planbook-lessons-context"], function (_exports, _tsBelt, _patchUpdater, _findCourseDate, _lodashEs, _defaultPlanbookLessonsActivity, _base, _lesson, _defaultLessonModels, _moment, _planbook, _defaultPlanbookLessonsContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = copyAllLessonsForDayAction;
  _exports.patches = patches;
  _exports.narrative = narrative;

  function copyAllLessonsForDayAction(payload) {
    return {
      name: "PLANBOOK_COPY_ALL_LESSONS_FOR_DAY",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    // See LESSON_COPY
    // IF use this again, abstract!
    let patch = _patchUpdater.default.create("user", payload.currentUserId).set("attributes.clipboard.day", {
      // The setting of the parentCardStack is handled in the function that calls this.
      lessonsForCourses: payload.lessonsForCourses
    }).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let planbook = findFn("planbook", payload.planbookId);
    let formattedDate = (0, _moment.default)(payload.dateString).format("ddd, MMM. D, YYYY");
    let analyticsEvents = (0, _tsBelt.pipe)(payload.lessonsForCourses, _tsBelt.A.filter(cardStack => {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(findFn("course", cardStack.courseId), payload.dateString);
      return (courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.cardStackId) !== undefined && courseDate.attributes.cardStackId !== null;
    }), _tsBelt.A.map(cardStack => {
      let courseDate = (0, _findCourseDate.findCourseDateCustom)(findFn("course", cardStack.courseId), payload.dateString);
      let cardStackId = courseDate === null || courseDate === void 0 ? void 0 : courseDate.attributes.cardStackId; // We should never hit this because of the filter above ^

      if (cardStackId === undefined || cardStackId === null) return null;
      let models = (0, _defaultLessonModels.default)({
        cardStackId: cardStackId,
        planbookId: payload.planbookId
      }, findFn, userId);
      return { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbookId,
        root_type: "planbook",
        date_plan_scheduled_for: payload.dateString,
        primary_object_id: cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "copy",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbookId),
        primary_object_type: "lesson",
        primary_object_specifier: null,
        adverbial_verb: "copying",
        adverbial_object_type: "lesson",
        adverbial_object_specifier: "entire_day",
        adverbial_object_id: cardStackId,
        adverbial_object_possessive: (0, _lesson.getPossessiveForLesson)(findFn, userId, payload.ownerIds || []),
        adverbial_object_id_and_ancestor_ids: [payload.planbookId]
      };
    }), arr => (0, _lodashEs.compact)(arr));
    return {
      title: "Copied an entire day of Lessons",
      titleForAnalytics: "Lesson - Copy Entire Day",
      description: `Copied a day's worth of lessons from ${formattedDate}`,
      context: (0, _defaultPlanbookLessonsContext.default)(planbook, payload),
      activity: (0, _defaultPlanbookLessonsActivity.default)(payload.planbookId, userId),
      newAnalyticsEvents: analyticsEvents
    };
  }
});