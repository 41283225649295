define("cc-frontend/lib/actions/lesson/LESSON_VIEW_HISTORY", ["exports", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _base, _planbook, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = viewLessonHistoryAction;

  function viewLessonHistoryAction(payload) {
    return {
      name: "LESSON_VIEW_HISTORY",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, findFn, userId) {
    var _models$cardStack, _models$course;

    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let context = (0, _defaultLessonContext.default)(payload, models);
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbookId,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "view",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "history",
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_specifier: null,
      adverbial_object_id: null,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: [payload.planbookId, payload.courseId, payload.cardStackId]
    };
    return {
      title: "Viewing Lesson History",
      titleForAnalytics: "Lesson - View History",
      description: `Viewing history for the lesson: ${(_models$cardStack = models.cardStack) === null || _models$cardStack === void 0 ? void 0 : _models$cardStack.attributes.title} in the course: ${(_models$course = models.course) === null || _models$course === void 0 ? void 0 : _models$course.attributes.title}`,
      activity: (0, _defaultLessonActivity.default)(payload, models),
      context: context,
      newAnalyticsEvents: [newEvent]
    };
  }
});