define("cc-frontend/services/socket-subscriber", ["exports", "cc-frontend/app", "lodash", "cc-frontend/config/environment", "compare-versions"], function (_exports, _app, _lodash, _environment, _compareVersions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SocketSubscriber = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SocketSubscriber extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "socket", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "dialog", _descriptor4, this);

      _defineProperty(this, "lastSuccessfulPing", new Date());

      _defineProperty(this, "serverVersion", null);

      _defineProperty(this, "serverHostname", null);

      _defineProperty(this, "serverRelease", null);

      _defineProperty(this, "_heartbeatLooperTimer", null);

      _defineProperty(this, "lastSequenceNumberReceived", 0);

      _defineProperty(this, "_requestedRevisions", []);

      _defineProperty(this, "_requestedSequenceNumbers", []);
    }

    heartbeat() {
      // Refresh the page if it's been more than 1 day since the last successful ping.
      let secondsSinceLastSuccessfulPing = Math.floor((new Date() - this.lastSuccessfulPing) / 1000); // 1 day

      if (secondsSinceLastSuccessfulPing > 86400) {
        console.log("Session has expired. Reloading page...");
        this.socket.reloadPage();
      }

      if (Ember.get(this, "socket.subscriptions.length") > 2) {
        this.socket.pushHeartbeat(data => {
          this.lastSuccessfulPing = new Date();
          this.socket.isOnline = true;
          /**
           * Log the server version, host, and release
           * This helps us know when a server release actually activates
           *
           * Check if it's null so we don't log it the first time.
           */

          if (data.serverVersion !== this.serverVersion) {
            if (this.serverVersion !== null) {
              console.log(`Syncing server is at release: ${data.serverVersion}`);
            }

            this.serverVersion = data.serverVersion;
          }

          if (data.hostname !== this.serverHostname) {
            if (this.serverHostname !== null) {
              console.log(`Connected to new syncing server: ${data.hostname}`);
            }

            this.serverHostname = data.hostname;
          }

          if (data.release !== this.serverRelease) {
            if (this.serverRelease !== null) {
              console.log(`Syncing server was upgraded to: ${data.release}`);
            }

            this.serverRelease = data.release;
          }

          if (data.lastSequenceNumberSent > this.lastSequenceNumberReceived) {
            this._requestLostSequence(this.lastSequenceNumberReceived + 1);
          } else {
            let date = new Date();
            console.log(`Sync Complete. Page is ${document.visibilityState}. Update #${data.lastSequenceNumberSent} received. (${date.toISOString()} - ${Ember.get(this, "session.id")})`);
          }

          let minimumVersion = data.clientVersion.replace("Cc.", "");

          if (_compareVersions.default.compare(_environment.default.CLIENT_VERSION, minimumVersion, "<")) {
            console.log(`Must update to new version. We have ${_environment.default.CLIENT_VERSION} and we need to refresh to have: ${data.clientVersion}`);
            setTimeout(() => {
              this.showReloadOnNewVersionNotice();
            }, Math.random() * 10000);
            setTimeout(function () {
              window.location.reload();
            }, 300000 + Math.random() * 300000);
          }
        });
      }
    }

    heartbeatLoop() {
      this._heartbeatLooperTimer = Ember.run.later(() => {
        this.heartbeatLoop();
        this.heartbeat();
      }, 22000);
    }

    willDestroy() {
      if (this._heartbeatLooperTimer) Ember.run.cancel(this._heartbeatLooperTimer);
      super.willDestroy(...arguments);
    }

    showReloadOnNewVersionNotice() {
      if (Ember.get(this, "session.userId")) {
        Ember.get(this, "dialog").blank("dialogs/alert-reload-on-new-version", {
          className: "dialog--create-annotation"
        }).then(() => {
          window.location.reload();
        }).catch(error => {
          window.location.reload();
          if (error instanceof Error) (0, _app.handleError)(error);
        });
      }
    }

    onModelUpdate(response, outOfSequencePatch) {
      // Find if we're missing a patch => requestMissingUpdate
      // Find out if the server forgot to give us a patch => requestPatchForModel
      // Find out if we need to just update the version
      // Find out if we need to apply the patch
      let patches = _lodash.default.isArray(response.data) ? response.data : [response.data];

      _lodash.default.chain(patches).map(patch => this._checkSequenceNumber(patch, outOfSequencePatch)).compact().forEach(patch => this._processNewPatch(patch)).value();
    }

    _checkSequenceNumber(patch, outOfSequencePatch) {
      // If it's the product of requesting a revision, then, we don't
      // care about it's order in the sequence.
      if (outOfSequencePatch === true) {
        return patch;
      } else {
        // Expected patch
        if (patch.meta.sequenceNumber === this.lastSequenceNumberReceived + 1) {
          this.lastSequenceNumberReceived = patch.meta.sequenceNumber;
          return patch;
        } // A historial patch


        if (patch.meta.sequenceNumber <= this.lastSequenceNumberReceived) {
          return null;
        } // A patch in the future


        if (patch.meta.sequenceNumber > this.lastSequenceNumberReceived + 1) {
          this._requestLostSequence(this.lastSequenceNumberReceived + 1);

          return null;
        }
      }
    }

    /**
      The problem with this as written is it will apply a patch two times if we've already applied
      it locally and then get the same patch back from the server.
       It would be better if we did one of a couple things:
       - Request snapshots from the server. This will prevent applying multiple patches
      - Don't apply the patch locally until we can apply the patches from the server
      - Don't use "push" -- only use "addToSet". The problem is you can't do this with Mongo currently
        https://docs.mongodb.com/manual/reference/operator/update/addToSet/#value-to-add-is-a-document
      - Don't apply the patch if we've already applied it locally. This is tough as we might need to
        reapply it if it changes an early patch
      - Make a snapshot that we apply the old patches to and then reapply them.
      */
    _processNewPatch(patch) {
      // For testing
      // if (Math.random() > 0.8) return;
      // Accept if:
      // the patch receieved if: the version produced is one more than our version. Otherwise, we're missing a patch.
      let currentModel = Ember.get(this, "store").findInMemory(patch.attributes.document.modelType, patch.attributes.document.id);
      if (Ember.isNone(currentModel)) return;
      let weHaveRevision = Ember.get(currentModel, "attributes._revision") || 0;
      let revisionAfterPatch = patch.attributes.revisionAfterServerApplication; // console.log('patch', currentModel.type, patch, currentModel.attributes._revision)

      if (weHaveRevision + 1 === revisionAfterPatch) {
        this._acceptPatch(patch, currentModel);

        return;
      } // A historical patch we've already applied


      if (weHaveRevision >= revisionAfterPatch) {
        return;
      } // Request a missing patch


      if (weHaveRevision < revisionAfterPatch) {
        // This is hacky -- if we have a fake lesson that we're fillin in from the template. don't request it as it doesn't exist.
        if (weHaveRevision === 0 && patch.attributes.document.modelType === "card-stack") return;
        this.requestRevision(patch.attributes.document.modelType, patch.attributes.document.id, weHaveRevision);
        return;
      }
    }

    _acceptPatch(patch, currentModel) {
      if (patch.type !== "patch-summary") {
        Ember.get(this, "store").applyForeignPatchToStore(patch.attributes);
      }

      Ember.set(currentModel, "attributes._revision", patch.attributes.revisionAfterServerApplication);
      Ember.set(currentModel, "meta.lastUpdateFromServer", new Date());
    }

    requestRevision(modelName, id, revision) {
      let identifier = `${modelName}:${id}:${revision}`;
      if (_lodash.default.includes(this._requestedRevisions, identifier)) return;

      this._requestedRevisions.push(identifier);

      console.log("requesting", identifier);
      this.socket.channel.push("request-revision", {
        modelType: modelName,
        id: id,
        revision: revision
      }).receive("ok", response => {
        this.onModelUpdate(response, true);

        _lodash.default.pull(this._requestedRevisions, identifier);
      }).receive("error", response => {
        _lodash.default.pull(this._requestedRevisions, identifier);
      }).receive("timeout", response => {
        _lodash.default.pull(this._requestedRevisions, identifier);
      });
    }

    _requestLostSequence(sequenceNumber) {
      if (_lodash.default.includes(this._requestedSequenceNumbers, sequenceNumber)) return;

      this._requestedSequenceNumbers.push(sequenceNumber);

      console.log("requesting lost sequence", sequenceNumber);
      this.socket.channel.push("request-lost-patch", {
        sequenceNumber: sequenceNumber
      }).receive("ok", response => {
        console.log("received lost sequence", sequenceNumber, response);
        this.onModelUpdate(response);

        _lodash.default.pull(this._requestedSequenceNumbers, sequenceNumber);

        if (_lodash.default.isArray(response.data) && response.data.length > 0) {
          this.heartbeat();
        }
      }).receive("error", response => {
        _lodash.default.pull(this._requestedSequenceNumbers, sequenceNumber);
      }).receive("timeout", response => {
        _lodash.default.pull(this._requestedSequenceNumbers, sequenceNumber);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "socket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SocketSubscriber;
});