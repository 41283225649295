define("cc-frontend/lib/actions/app-state/VIEW_PLANBOOK", ["exports", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/create-narrative"], function (_exports, _base, _lodashEs, _createNarrative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = viewPlanbookAction;

  function viewPlanbookAction(payload) {
    return {
      name: "VIEW_PLANBOOK",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, _findFn, _userId) {
    let titleForAnalytics = "";
    let primaryObjectSpecifier;

    if (payload.title === "planbookv5.planner.day") {
      titleForAnalytics = "Planbook View - Day";
      primaryObjectSpecifier = "day";
    } else if (payload.title === "planbookv5.planner.week") {
      titleForAnalytics = "Planbook View - Week";
      primaryObjectSpecifier = "week";
    } else if (payload.title === "planbookv5.planner.month") {
      titleForAnalytics = "Planbook View - Month";
      primaryObjectSpecifier = "month";
    } else if (payload.title === "planbookv5.planner.timeline") {
      titleForAnalytics = "Planbook View - Unit Timeline";
      primaryObjectSpecifier = "timeline";
    } else if (payload.title === "planbookv5.planner.outline") {
      titleForAnalytics = "Planbook View - Outline";
      primaryObjectSpecifier = "outline";
    } else if (payload.title === "planbookv5.sharing") {
      titleForAnalytics = "Planbook View - Sharing";
      primaryObjectSpecifier = "sharing";
    } else if (payload.title === "planbookv5.class-websites") {
      titleForAnalytics = "Planbook View - Class Websites";
      primaryObjectSpecifier = "class_websites";
    } else if (payload.title === "planbookv5.standards-tracker") {
      titleForAnalytics = "Planbook View - Standards Tracker";
      primaryObjectSpecifier = "standards_tracker";
    } else if (payload.title === "planbookv5.search") {
      titleForAnalytics = "Planbook View - Search Lessons";
      primaryObjectSpecifier = "search_page";
    }

    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbookId,
      root_type: "planbook",
      primary_object_type: "planbook",
      primary_possessive: "owns",
      primary_object_id_and_ancestor_ids: [payload.planbookId],
      primary_verb: "view",
      primary_object_specifier: primaryObjectSpecifier,
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_specifier: null
    };
    return (0, _createNarrative.default)({
      title: `Navigated to ${newEvent.primary_object_specifier}`,
      titleForAnalytics: titleForAnalytics,
      description: `Navigated to ${newEvent.primary_object_specifier}`,
      context: {
        routeName: payload.title
      },
      newAnalyticsEvents: [newEvent]
    });
  }
});