define("cc-frontend/components/planner/modals/edit-course", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div style={{html-safe (concat "z-index: " (mult 1001 @order))}}>
    <div local-class="modal-wrapper" style={{html-safe (concat "z-index: " (mult 1001 @order))}}>
      <div local-class="modal">
  
        <div local-class="exit-button-wrapper">
          <span local-class="exit-button" {{on "click" @closeModal}}>
            <Icons::IosCloseEmpty />
          </span>
        </div>
        <div local-class="modal-content" class="tw-pt-16">
          {{#if @model.course}}
            <Planner::Course::CreateEdit::NameAndMore
              @course={{@model.course}}
              @findPreviousTime={{@model.findPreviousTime}}
              @rotationCalendar={{@model.rotationCalendar}}
              @planbook={{@model.planbook}}
              @close={{@closeModal}}
              @showDeleteButton={{@model.showDeleteButton}}
            />
          {{/if}}
        </div>
      </div>
    </div>
    {{! I think people will be ignored if this automatically closes  when people click on the background}}
    {{! Thus, no on click }}
    <div local-class="background" style={{html-safe (concat "z-index: " (mult 1000 @order))}}></div>
  </div>
  */
  {
    "id": "tJFE62pe",
    "block": "[[[10,0],[15,5,[28,[37,0],[[28,[37,1],[\"z-index: \",[28,[37,2],[1001,[30,1]],null]],null]],null]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,3],[\"modal-wrapper\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-course.css\"]]]]]],[15,5,[28,[37,0],[[28,[37,1],[\"z-index: \",[28,[37,2],[1001,[30,1]],null]],null]],null]],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,3],[\"modal\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-course.css\"]]]]]],[12],[1,\"\\n\\n      \"],[10,0],[15,0,[29,[[28,[37,3],[\"exit-button-wrapper\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-course.css\"]]]]]],[12],[1,\"\\n        \"],[11,1],[16,0,[29,[[28,[37,3],[\"exit-button\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-course.css\"]]]]]],[4,[38,4],[\"click\",[30,2]],null],[12],[1,\"\\n          \"],[8,[39,5],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[15,0,[29,[\"tw-pt-16 \",[28,[37,3],[\"modal-content\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-course.css\"]]]]]],[12],[1,\"\\n\"],[41,[30,3,[\"course\"]],[[[1,\"          \"],[8,[39,7],null,[[\"@course\",\"@findPreviousTime\",\"@rotationCalendar\",\"@planbook\",\"@close\",\"@showDeleteButton\"],[[30,3,[\"course\"]],[30,3,[\"findPreviousTime\"]],[30,3,[\"rotationCalendar\"]],[30,3,[\"planbook\"]],[30,2],[30,3,[\"showDeleteButton\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[1,\"  \"],[10,0],[15,0,[29,[[28,[37,3],[\"background\"],[[\"from\"],[\"cc-frontend/components/planner/modals/edit-course.css\"]]]]]],[15,5,[28,[37,0],[[28,[37,1],[\"z-index: \",[28,[37,2],[1000,[30,1]],null]],null]],null]],[12],[13],[1,\"\\n\"],[13]],[\"@order\",\"@closeModal\",\"@model\"],false,[\"html-safe\",\"concat\",\"mult\",\"local-class\",\"on\",\"icons/ios-close-empty\",\"if\",\"planner/course/create-edit/name-and-more\"]]",
    "moduleName": "cc-frontend/components/planner/modals/edit-course.hbs",
    "isStrictMode": false
  });

  class EditCourseModalComponent extends _glimmerComponent.default {}

  _exports.default = EditCourseModalComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EditCourseModalComponent);
});