define("cc-frontend/lib/actions/app-state/PRINT_CARD_STACK", ["exports", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/create-narrative", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models"], function (_exports, _base, _lodashEs, _createNarrative, _defaultLessonContext, _defaultLessonModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = printCardStackAction;

  function printCardStackAction(payload) {
    return {
      name: "PRINT_CARD_STACK",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let context = (0, _defaultLessonContext.default)(payload, models);
    let titleForAnalytics = payload.perspective === "timeline" ? "Lesson Print - Unit" : "Lesson Print";
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_verb: "print",
      primary_possessive: "owns",
      primary_object_type: "lesson",
      primary_object_id: payload.cardStackId,
      primary_object_specifier: null,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      adverbial_verb: null,
      adverbial_object_possessive: null,
      adverbial_object_type: payload.perspective === "timeline" ? "unit" : null,
      adverbial_object_id: payload.cardStackId,
      adverbial_object_specifier: null,
      adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
    };
    return (0, _createNarrative.default)({
      title: `Lesson Printed`,
      titleForAnalytics: titleForAnalytics,
      description: `Printing from the ${payload.perspective}`,
      context: context,
      newAnalyticsEvents: [newEvent]
    });
  }
});