define("cc-frontend/components/planner/modals/edit-course.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal-wrapper": "_modal-wrapper_ekyapy",
    "modal": "_modal_ekyapy",
    "modal-content": "_modal-content_ekyapy",
    "exit-button-wrapper": "_exit-button-wrapper_ekyapy",
    "exit-button": "_exit-button_ekyapy",
    "background": "_background_ekyapy"
  };
  _exports.default = _default;
});