define("cc-frontend/templates/print/course-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gnyso++I",
    "block": "[[[41,[30,0,[\"normalPageBreaks\"]],[[[1,\"  \"],[10,0],[14,0,\"ccp-page-header ccp-page-header--for-each-day\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"ccp-page-header__title\"],[12],[1,\"\\n      \"],[1,[30,0,[\"headerText\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"dateStrings\"]]],null]],null],null,[[[1,\"  \"],[10,0],[15,0,[29,[\"ccp-course-date \",[52,[30,0,[\"pageBreakAfterClass\"]],\"page-break\"]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"ccp-day-header\"],[12],[1,\"\\n\"],[41,[30,0,[\"pageBreakAfterClass\"]],[[[1,\"        \"],[10,2],[14,0,\"ccp-page-header__title\"],[12],[1,\"\\n          \"],[1,[30,0,[\"headerText\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,\"h1\"],[14,0,\"ccp-day-header__date-range\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,1],\"dddd, MMMM Do, YYYY\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,4],null,[[\"@course\",\"@dateString\",\"@planbook\",\"@useStudentVersion\",\"@rotationCalendar\"],[[30,0,[\"course\",\"content\"]],[30,1],[30,0,[\"planbook\",\"content\"]],false,[30,0,[\"rotationCalendar\",\"content\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@lesson\",\"@showStandardDescriptions\",\"@showComments\",\"@isFilteringCards\",\"@visibleCards\",\"@course\",\"@courseDate\",\"@perspective\"],[[30,3],[30,0,[\"showStandardDescriptions\"]],[30,0,[\"showComments\"]],[30,0,[\"isFilteringCards\"]],[30,0,[\"visibleCards\"]],[30,0,[\"course\",\"content\"]],[30,2],[30,0,[\"perspective\"]]]],null],[1,\"\\n    \"]],[2,3,4,5]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null]],[\"dateString\",\"courseDate\",\"lesson\",\"units\",\"lessonIsFulfilled\"],false,[\"if\",\"each\",\"-track-array\",\"moment-format\",\"planner/card-stacks/lesson/provider\",\"print/lesson\"]]",
    "moduleName": "cc-frontend/templates/print/course-date.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});