define("cc-frontend/components/planner/course/create-edit/name-and-more.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "course-edit-wrapper": "_course-edit-wrapper_1apezn",
    "course-edit-content": "_course-edit-content_1apezn",
    "course-edit-header": "_course-edit-header_1apezn",
    "name-colorpicker-wrapper": "_name-colorpicker-wrapper_1apezn",
    "course-title-input": "_course-title-input_1apezn",
    "course-color-picker": "_course-color-picker_1apezn",
    "course-color-picker__color": "_course-color-picker__color_1apezn",
    "course-time-question-text": "_course-time-question-text_1apezn",
    "course-time-question-button__group": "_course-time-question-button__group_1apezn",
    "course-time-question-button": "_course-time-question-button_1apezn",
    "day-by-day-list": "_day-by-day-list_1apezn",
    "course-time-input-start-end-group": "_course-time-input-start-end-group_1apezn",
    "course-time-label-input-group": "_course-time-label-input-group_1apezn",
    "course-time-input-label": "_course-time-input-label_1apezn",
    "course-time-done-button": "_course-time-done-button_1apezn",
    "course-time-input": "_course-time-input_1apezn",
    "choose-template-button": "_choose-template-button_1apezn",
    "choose-template-icon-text-group": "_choose-template-icon-text-group_1apezn",
    "delete-button": "_delete-button_1apezn",
    "done-button": "_done-button_1apezn",
    "footer": "_footer_1apezn"
  };
  _exports.default = _default;
});