define("cc-frontend/lib/actions/app-state/PLANBOOK_PRINT", ["exports", "cc-frontend/models/analytics/base", "lodash-es", "cc-frontend/lib/actions/create-narrative"], function (_exports, _base, _lodashEs, _createNarrative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = planbookPrintAction;

  function planbookPrintAction(payload) {
    return {
      name: "PLANBOOK_PRINT",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, findFn, userId) {
    let titleForAnalytics = "";
    let primaryObjectSpecifier;

    switch (payload.title) {
      case "course-date:single":
      case "course-date:range":
        titleForAnalytics = "Planbook Print - Course";
        primaryObjectSpecifier = "lesson";
        break;

      case "planbook-date:single":
        titleForAnalytics = "Planbook Print - Day";
        primaryObjectSpecifier = "day";
        break;

      case "planbook-date:range:columns":
      case "planbook-date:range:full-page":
        titleForAnalytics = "Planbook Print - Week";
        primaryObjectSpecifier = "week";
        break;

      case "planbook-month:grid":
        titleForAnalytics = "Planbook Print - Month";
        primaryObjectSpecifier = "month";
        break;

      case "course-outline":
        titleForAnalytics = "Planbook Print - Outline";
        primaryObjectSpecifier = "outline";
        break;

      case "course-unit":
        titleForAnalytics = "Planbook Print - Unit";
        primaryObjectSpecifier = "unit";
        break;

      case "course-units":
        titleForAnalytics = "Planbook Print - Unit Timeline";
        primaryObjectSpecifier = "timeline";
        break;

      case "print.planbook-standards":
        titleForAnalytics = "Planbook Print - Standards Tracker";
        primaryObjectSpecifier = "standards_tracker";
        break;

      default:
        titleForAnalytics = ""; // Handle any other cases if needed

        primaryObjectSpecifier = null;
    }

    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: payload.planbookId,
      root_type: "planbook",
      primary_object_type: "planbook",
      primary_possessive: "owns",
      primary_object_id_and_ancestor_ids: [payload.planbookId],
      primary_verb: "print",
      primary_object_specifier: primaryObjectSpecifier,
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_specifier: null
    };
    return (0, _createNarrative.default)({
      title: `Print ${primaryObjectSpecifier}`,
      titleForAnalytics: titleForAnalytics,
      description: `Printing the ${newEvent.primary_object_specifier}`,
      context: {},
      newAnalyticsEvents: [newEvent]
    });
  }
});