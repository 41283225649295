define("cc-frontend/lib/actions/lesson/LESSON_STANDARDS_REPLACE", ["exports", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _lodashEs, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lessonStandardsReplaceAction;

  function lessonStandardsReplaceAction(payload) {
    return {
      name: "LESSON_STANDARDS_REPLACE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.cardId
    }).set("embedded", "attributes.standards", payload.standards).patch;

    return [patch];
  }

  function undoPatches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.cardId
    }).set("embedded", "attributes.standards", JSON.parse(JSON.stringify(payload.previousStandards))).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    var _payload$standards;

    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let diff = ((_payload$standards = payload.standards) === null || _payload$standards === void 0 ? void 0 : _payload$standards.length) - payload.previousStandards.length;
    let verb = diff > 0 ? "Added" : "Removed";
    let noun = diff === 1 ? "standard" : "standards";
    let absoluteDiff = Math.abs(diff);
    let titleForAnalytics;
    let adverbialObjectSpecifier;

    if (models.cardStackType === "unit") {
      titleForAnalytics = "Lesson - Update Standards - Unit";
      adverbialObjectSpecifier = "unit";
    } else {
      titleForAnalytics = "Lesson - Update Standards";
      adverbialObjectSpecifier = "lesson";
    }

    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "standards",
      adverbial_verb: "adding",
      adverbial_object_type: "standard",
      adverbial_object_specifier: adverbialObjectSpecifier,
      adverbial_object_id: payload.cardId,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
    };
    return {
      title: "Changed Lesson Standards",
      titleForAnalytics: titleForAnalytics,
      description: `${verb} ${absoluteDiff} ${noun}`,
      context: (0, _defaultLessonContext.default)(payload, models),
      activity: (0, _defaultLessonActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }
});