define("cc-frontend/lib/actions/attachments/DOWNLOAD_ATTACHMENT", ["exports", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/lib/actions/lesson/default-lesson-activity"], function (_exports, _base, _planbook, _lodashEs, _defaultLessonContext, _defaultLessonModels, _defaultLessonActivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = downloadAttachmentAction;
  _exports.narrative = narrative;

  function downloadAttachmentAction(payload) {
    return {
      name: "DOWNLOAD_ATTACHMENT",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let context = (0, _defaultLessonContext.default)(payload, models);
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "download",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "attachment",
      primary_object_specifier: null,
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_specifier: null,
      adverbial_object_id: null,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: null
    };
    return {
      title: "Downloaded Attachment",
      titleForAnalytics: "Attachment - Download",
      description: `Downloaded ${payload.attachment.type} Attachment`,
      activity: (0, _defaultLessonActivity.default)(payload, models),
      context: context,
      newAnalyticsEvents: [newEvent]
    };
  }
});