define("cc-frontend/lib/actions/lesson/LESSON_STANDARDS_COPY", ["exports", "cc-frontend/lib/patch-updater", "lodash", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "cc-frontend/lib/actions/lesson/default-lesson-activity"], function (_exports, _patchUpdater, _lodash, _defaultLessonContext, _defaultLessonModels, _base, _planbook, _defaultLessonActivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lessonStandardsCopyAction;

  function lessonStandardsCopyAction(payload) {
    return {
      name: "LESSON_STANDARDS_COPY",
      payload: payload,
      patches: patches(payload),
      undoPatches: null,
      narrative: (0, _lodash.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("user", payload.currentUserId).set("attributes.clipboard.card", {
      type: "card-standards",
      attributes: {
        standards: payload.standards
      }
    }).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let context = (0, _defaultLessonContext.default)(payload, models);
    let standards = (0, _lodash.map)(payload.standards, standard => {
      return standard.code;
    });
    let standardSets = (0, _lodash.map)(payload.standards, standard => {
      return `${standard.jurisdiction} - ${standard.grades} - ${standard.subject}`;
    });
    let newContext = (0, _lodash.assign)({}, context, {
      standards,
      standardSets
    });
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "copy",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "standards",
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_specifier: null,
      adverbial_object_id: null,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
    };
    return {
      title: "Lesson Standard Copied",
      titleForAnalytics: "Standards - copy",
      description: `Copied from ${standardSets}`,
      context: newContext,
      activity: (0, _defaultLessonActivity.default)(payload, models),
      newAnalyticsEvents: [newEvent]
    };
  }
});