define("cc-frontend/lib/actions/lesson/LESSON_CARD_ADD_COMMENT", ["exports", "cc-frontend/lib/patch-updater", "lodash-es", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "date-fns", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _lodashEs, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _dateFns, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lessonCardAddCommentAction;

  function lessonCardAddCommentAction(payload) {
    return {
      name: "LESSON_CARD_ADD_COMMENT",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.sectionId
    }).push("embedded", "attributes.comments", payload.comment).patch;

    return [patch];
  }

  function undoPatches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
      id: payload.sectionId
    }).pull("embedded", "attributes.comments", {
      id: payload.comment.id
    }).patch;

    return [patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let card = (0, _lodashEs.find)(models.cardStack.attributes.cards, {
      id: payload.sectionId
    });
    if (card === undefined) throw new Error("No Card passed in card stack to comment on ");
    let cardTitle = "title" in card.attributes ? card.attributes.title : null;
    let commenterName = payload === null || payload === void 0 ? void 0 : payload.comment.commenterName;
    let commenterId = payload === null || payload === void 0 ? void 0 : payload.comment.commenterId;
    let context = (0, _defaultLessonContext.default)(payload, models);
    let newContext = (0, _lodashEs.assign)({}, context, {
      cardTitle: cardTitle,
      commenterName,
      commenterId,
      commentText: payload.comment.text,
      commentCreatedAtFormattedDate: (0, _dateFns.format)((0, _dateFns.parseISO)(payload === null || payload === void 0 ? void 0 : payload.comment.createdAt), "EEE, LLL. d, yyyy"),
      commentCreatedAt: payload === null || payload === void 0 ? void 0 : payload.comment.createdAt,
      dateString: models.dateStringForCardStack
    });
    let titleForAnalytics;
    let adverbialVerb;

    if (payload.isReply) {
      titleForAnalytics = "Comment - Responded";
      adverbialVerb = "responding";
    } else {
      titleForAnalytics = "Comment - Add";
      adverbialVerb = "adding";
    }

    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      primary_object_id: payload.cardStackId,
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "comment",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "comments",
      adverbial_verb: adverbialVerb,
      adverbial_object_type: "comment",
      adverbial_object_specifier: null,
      adverbial_object_id: payload.comment.id,
      adverbial_object_id_and_ancestor_ids: [...models.cardStackIdAndAncestorIds, payload.comment.id]
    };
    return {
      title: "Added a comment",
      titleForAnalytics: titleForAnalytics,
      description: `${commenterName} added a comment to the "${cardTitle}" card`,
      context: newContext,
      activity: (0, _defaultLessonActivity.default)(payload, models, "created"),
      newAnalyticsEvents: [newEvent]
    };
  }
});