define("cc-frontend/lib/actions/course/calendar/MOVE_LESSON_BY_AMOUNT", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "lodash-es", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _unitUtils, _lodashEs, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareBumpLessonForward = prepareBumpLessonForward;
  _exports.MOVE_LESSON_BY_AMOUNT = void 0;

  function prepareBumpLessonForward(payload) {
    return {
      name: MOVE_LESSON_BY_AMOUNT.name,
      payload: payload,
      patches: MOVE_LESSON_BY_AMOUNT.patches(payload),
      undoPatches: MOVE_LESSON_BY_AMOUNT.undoPatches(payload),
      narrative: (0, _lodashEs.partial)(MOVE_LESSON_BY_AMOUNT.narrative, payload)
    };
  }

  const MOVE_LESSON_BY_AMOUNT = {
    name: "MOVE_LESSON_BY_AMOUNT",

    patches(payload) {
      let schoolDays = payload.rotationCalendar.attributes.schoolDays;
      let cardStackId = (0, _unitUtils.getCardStackIdForDate)(payload.course.attributes.calendar.dates, payload.dateString);
      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let {
        transferrableArray,
        courseDatesWithoutLesson
      } = (0, _unitUtils.extractLesson)(cardStackId, payload.course.attributes.calendar.dates);
      let newStartDate = (0, _unitUtils.findNewDate)(payload.amount, payload.dateString, consolidatedCourseDatesOff, schoolDays);
      if (newStartDate === undefined) throw Error("No new start date found");
      let newCourseDates = (0, _unitUtils.insertRange)(transferrableArray, newStartDate, payload.amount, null, courseDatesWithoutLesson, consolidatedCourseDatesOff, schoolDays, true);
      let ret = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, schoolDays);
      let {
        courseDates,
        units
      } = ret;

      let patch = _patchUpdater.default.create("course", payload.course.id).set(`attributes.calendar.dates`, courseDates).set(`attributes.calendar.units`, units).inc(`attributes.calendar.version`, 1).patch; // patch.unitIdsOnTheMove   = unitIdsOnTheMove
      // patch.cardStackIdsOnTheMove = cardStackIdsOnTheMove


      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).inc("attributes.calendar.version", 1).patch;

      return [patch];
    },

    narrative(payload, findFn, userId) {
      var _lesson$attributes;

      let cardStackId = (0, _unitUtils.getCardStackIdForDate)(payload.course.attributes.calendar.dates, payload.dateString);
      let courseDate = null;
      let dateStringForCardStack = null;

      if (payload.course && cardStackId !== null) {
        courseDate = (0, _unitUtils.getCourseDateForCardStackId)(payload.course.attributes.calendar.dates, cardStackId);
      }

      if (courseDate && courseDate.type === "course-date-custom") {
        dateStringForCardStack = courseDate.attributes.dateString;
      }

      let lesson = cardStackId ? findFn("card-stack", cardStackId) || findFn("card-stack-summary", cardStackId) : null;
      let courseId = payload.course.id;
      let planbookId = payload.planbook.id;
      let lessonTitle = (lesson === null || lesson === void 0 ? void 0 : (_lesson$attributes = lesson.attributes) === null || _lesson$attributes === void 0 ? void 0 : _lesson$attributes.title) || "";
      let amount = Math.abs(payload.amount);
      let direction = payload.amount > 0 ? "forward" : "backwards";
      let dayOrDays = payload.amount === 1 ? "day" : "days";
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: dateStringForCardStack,
        primary_object_id: cardStackId,
        primary_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id],
        primary_verb: "move",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: null,
        adverbial_verb: "bumping",
        adverbial_object_type: "lesson",
        adverbial_object_specifier: "forward",
        adverbial_object_id: cardStackId,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id]
      };
      return {
        title: "Moved Lesson",
        titleForAnalytics: "Lesson - Move",
        description: `Moved "${lessonTitle}" ${direction} ${amount} ${dayOrDays}`,
        context: {
          triggeredBy: "Bump button",
          lessonTitle: lesson ? lesson.attributes.title : "[NEW LESSON]",
          courseTitle: payload.course.attributes.title,
          planbookTitle: payload.planbook.attributes.title,
          cardStackId: cardStackId ? cardStackId : "",
          courseId: payload.course.id,
          planbookId: payload.planbook.id
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `lesson-${cardStackId}`,
          to: [`course-edits:course-${courseId}`, `planbook-edits:planbook-${planbookId}`]
        },
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.MOVE_LESSON_BY_AMOUNT = MOVE_LESSON_BY_AMOUNT;
});