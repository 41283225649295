define("cc-frontend/lib/actions/course/date/COURSE_DATE_ANNOTATION_DELETE", ["exports", "cc-frontend/lib/dates-off-calculator", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es", "moment", "cc-frontend/lib/actions/create-narrative", "cc-frontend/lib/actions/course/date/default-course-date-activity", "cc-frontend/lib/actions/course/date/default-course-date-context", "cc-frontend/lib/actions/course/date/default-course-date-models"], function (_exports, _datesOffCalculator, _patchUpdater, _unitUtils, _base, _planbook, _lodashEs, _moment, _createNarrative, _defaultCourseDateActivity, _defaultCourseDateContext, _defaultCourseDateModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = courseDateAnnotationDeleteAction;

  function courseDateAnnotationDeleteAction(payload) {
    return {
      name: "COURSE_DATE_ANNOTATION_DELETE",
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let newAnnotations = (0, _lodashEs.reject)(payload.course.attributes.calendar.dateAnnotations, annotation => {
      return annotation.id === payload.annotation.id;
    });
    let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(newAnnotations);

    let patch = _patchUpdater.default.create("course", payload.course.id).pull("attributes.calendar.dateAnnotations", {
      id: payload.annotation.id
    }).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

    return [patch.patch];
  }

  function undoPatches(payload) {
    let oldAnnotations = JSON.parse(JSON.stringify(payload.course.attributes.calendar.dateAnnotations));
    let allDatesOff = (0, _datesOffCalculator.findCourseDatesOff)(oldAnnotations);

    let patch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dateAnnotations", oldAnnotations).set("attributes.calendar.datesOff", allDatesOff).inc("attributes.calendar.version", 1);

    return [patch.patch];
  }

  function narrative(payload, findFn, userId) {
    let models = (0, _defaultCourseDateModels.default)(payload, findFn, userId);
    let datePlanScheduledFor = payload.annotation.type === "date-annotation-course-custom-single" ? payload.annotation.attributes.dateString : null;
    let formattedDateString = payload.annotation.type === "date-annotation-course-default" ? `every ${payload.rotationTitle}` : (0, _moment.default)(payload.annotation.attributes.dateString).format("ddd, MMM. D, YYYY");
    let cardStackId = payload.annotation.type === "date-annotation-course-default" ? null : (0, _unitUtils.getCardStackIdForDate)(payload.course.attributes.calendar.dates, payload.annotation.attributes.dateString);
    let courseTitle = payload.course.attributes.title;
    let context = (0, _defaultCourseDateContext.default)(payload, models);
    let newContext = (0, _lodashEs.assign)({}, context, {
      noteTitle: (0, _lodashEs.get)(payload.annotation.attributes, "title"),
      type: payload.annotation.type
    });
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: datePlanScheduledFor,
      primary_object_id: cardStackId,
      primary_object_id_and_ancestor_ids: [models.planbook.id, payload.course.id],
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "lesson_slot",
      adverbial_verb: "toggling",
      adverbial_object_type: "lesson",
      adverbial_object_specifier: "on",
      adverbial_object_id: cardStackId,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: [models.planbook.id, payload.course.id]
    };
    return (0, _createNarrative.default)({
      title: "Turned class back on",
      titleForAnalytics: "Lesson - Toggle On",
      description: `Turned your ${courseTitle} class back on ${formattedDateString}`,
      context: newContext,
      activity: (0, _defaultCourseDateActivity.default)(payload, models, "removed"),
      newAnalyticsEvents: [newEvent]
    });
  }
});